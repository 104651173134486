import logo from './logo.svg';
import './App.css';

//images

import Swathii2 from './images/Swathii 2.png';
import Instagram from './images/Instagram_Glyph_Gradient.png';
import XTwitt from './images/logo-black.png';
import LinkedIn from './images/In-Blue-96@2x.png';

import Family from './images/family.png';

function App() {
  return (
    <div className="App">
      <div>
      <div className='logoBar'>
      <img src={Swathii2} className='logoSwathii' />
      </div>
      <div className='family'>
      <img src={Family} className='familyPhoto' />
      
      </div>
      <div className='lineBar'>
      <p className='lineOne'>"Get ready for a whole new experience – our fresh re-designed website is launching soon. Stay tuned!"</p>
      <p className='lineTwo'>For more information about our Real Estate Projects, Feel free to get in touch with us!</p>
      <p className='LineThree'>Email: vignesh@swathii.com | Phone: +91 9445360482 (WhatsApp Anytime) | Swathi Group</p>
      </div>
      <div className='socialMedia'>
      <a href='https://www.instagram.com/swathigroupcc/' target='blank'><img src={Instagram} className='Insta'/></a>
      <a href='https://x.com/weswathii' target='blank'><img src={XTwitt} className='XTwitt'/></a>
      <a href='https://www.linkedin.com/company/swathigroupcc/?viewAsMember=true' target='blank'><img src={LinkedIn} className='LinkedIn' /></a>
      </div>
      </div>
    </div>
  );
}

export default App;
